@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: "Inter", sans-serif !important;
}

body,.mce-content-body {
    color: #222222;
    font-family: "Inter", sans-serif !important;
}
h1 {
    font-size: 42px ; 
  }
  /* h1 a{
      font-size: normal ;
  } */
  h2 {
    font-size: 38px ;
  }
  
  h3 {
    font-size: 34px ;
  }
  
  h4 {
    font-size: 30px ;
  }
  
  h5 {
    font-size: 26px ;
  }
  
  h6 {
    font-size: 20px ;
  }
  .focus-tab{
    background-color: #f5f5f5 !important;
    color: #222 !important;
  }
  .tab{
    padding: 5px 15px;
    background-color: transparent;
    font-size: 13px;
    border-radius: 10px;
    text-transform: capitalize;
    font-weight: 400;
    color: #666b78;
    transition: all 0.2s;
    display: inline-block;
  }
  .tab:hover{
    color: #222;
  }
/* h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 130% !important;
} */


::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    /* scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent; */
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 100%;
}

.sidebar ::-webkit-scrollbar {
    width: 5px;
}

.sidebar ::-webkit-scrollbar-thumb {
    background-color: #3f5764 !important;
}

.dark-mode .sidebar ::-webkit-scrollbar-thumb {
    background-color: var(--primary-bg-color) !important;
}

.sidebar ::-webkit-scrollbar-track {
    background-color: #233e4d !important;
}

.dark-mode .sidebar ::-webkit-scrollbar-track {
    background-color: var(--sidebar-bg-color) !important;
}

.sidebar {
    transition: all 0.3s;
    /* width: 300px; */
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    min-height: 1px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50%;
}

.tox-tinymce {
    border: 1px solid #d1d5db !important;
    /* border-top: none !important;
    border-right: none !important;
    border-left: none !important; */
}
.tox .tox-edit-area::before{
    border: 1px solid transparent !important;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header{
    box-shadow: none !important;
    border-bottom: 1px solid #d1d5db !important;
}
/* 
.rdw-editor-wrapper {
    border: 1px solid #d1d5db;
    border-radius: 4px;
}

.DraftEditor-root {
    padding: 0 10px !important;
}

.rdw-image-modal-upload-option-label {
    display: block !important;
    word-break: break-all !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2 !important;
    font-size: 12px !important;
    padding: 10px 15px !important;
} */

/* Handle */
::-webkit-scrollbar-thumb {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    --tw-border-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-border-opacity));
    border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

:root {
    --bg-color: #35404c;
    --bg-color-rgb: 53, 64, 76;
    --bg-primary: #6a63FB;
    --bg-primary-rgb: 106, 99, 251;
    --bg-btn-hover: #476dbb;
    --bg-btn-hover-rgb: 71, 109, 187;
}

.bg {
    background-color: #74EBD5;
    background-image: linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%);
}

.bg .form {
    box-shadow: 0 20px 45px 0 rgb(0, 0, 0, 0.2);
}

.bg-btn {
    background-color: var(--bg-primary);
    transition: all 0.3s;
}

.bulk-btn {
    color: #222222;
    background-color: white;
    border: 1px solid rgb(203, 213, 219);
    padding: 6px 15px;
    border-radius: 4px;
    transition: all 0.2s;
    font-size: 13px;
    font-weight: 500;
}

.filter-btn {
    background-color: white;
    border: 1px solid rgb(203, 213, 219);
    width: 40px;
    height: 40px;
    /* padding: 6px 15px; */
    border-radius: 4px;
    transition: all 0.2s;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bulk-btn:hover,
.filter-btn:hover {
    background-color: rgb(249, 250, 251);
}

table tbody tr td {
    min-height: 50px !important;
}

.bg-btn:hover {
    background-color: var(--bg-btn-hover);
}

.border-primary{
    border: 1px solid var(--bg-primary);
}
.secondary-btn {
    border: 1px solid var(--bg-primary);
    background-color: white;
    color: var(--bg-primary);
    transition: all 0.3s;
    border-radius: 4px !important;
    padding: 8px 20px !important;
}

.secondary-btn:hover {
    background-color: var(--bg-primary);
    color: white;
}

.text-link {
    color: var(--bg-primary) !important;
    transition: all 0.3s;
}

.text-link:hover {
    color: var(--bg-btn-hover) !important;
}

.btn {
    background-color: var(--bg-primary) !important;
    border-radius: 4px !important;
    padding: 8px 20px !important;
    border: 1px solid var(--bg-primary);
}

.btn:hover {
    background-color: var(--bg-btn-hover) !important;
}

table thead tr,
.buGObk {
    background-color: white;
    color: #666b78;
    font-weight: 500;
    text-transform: uppercase;
    min-height: 45px !important;
    font-size: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
}

/* mui Popover CSS */
/* .MuiPaper-root.MuiPaper-elevation.MuiPopover-paper {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
} */

.react-datepicker-popper {
    transform: translate(0px, 38px) !important;
    padding-top: 10px !important;
}

.data-loader {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 4px solid #e4e3e3;
    border-top: 4px solid var(--bg-color);
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
    background-color: #f1f1f1 !important;
    color: black !important;
}

.react-datepicker-popper {
    z-index: 999 !important;
    height: 100% !important;
}

.menu-item,
.menu-item-close {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    transition: all 0.3s;
    font-size: 14px !important;
    padding: 9px !important;
}

.menu-item-close {
    color: #88a1b1 !important;
}

.menu-item span,
.menu-item-close span {
    font-size: 14px;
    letter-spacing: 0.5s !important;
}

.menu-item::after,
.menu-item-close::after {
    content: '';
    width: 100% !important;
    height: 100% !important;
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: all 0.3s;
    border-radius: 10px;
}

.fixed-sidebar .menu-item-close::after,
.fixed-sidebar .menu-item-close::before {
    color: #6f7e89;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.menu-text {
    transition: all 3s !important;
    font-size: 14px !important;
}

.close.sidebar .menu-text {
    display: none;
    transition: all 3s !important;
}

.min-w-\[240px\] .sidebar .menu-text {
    display: block;
}

.menu-item,
.menu-item-close {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    transition: all 0.3s;
    font-size: 14px !important;
    padding: 9px !important;
    background-color: transparent;
}

.menu-item-close {
    color: #88a1b1 !important;
}

.menu-item span,
.menu-item-close span {
    font-size: 14px;
    letter-spacing: 0.5s !important;
}

.menu-item::after,
.menu-item-close::after {
    content: '';
    width: 100% !important;
    height: 100% !important;
    background-color: #223a4b;
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: all 0.3s;
    border-radius: 10px;
}

.fixed-sidebar .menu-item::after,
.fixed-sidebar .menu-item-close::after {
    background-color: #3f5764;
}

/* .menu-item::before,
.menu-item-close::before {
    content: '';
    width: 2px;
    height: 100%;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s;
} */
.fixed-sidebar .menu-item-close::after,
.fixed-sidebar .menu-item-close::before {
    color: #6f7e89;
}

.menu-item-close::after {
    opacity: 0;
    color: #5e717c !important;
}

.menu-item-close::before {
    top: 100%;
    color: #5e717c !important;
}

select {
    appearance: none;
    padding: 8px 30px 8px 12px !important;
    font-size: 14px !important;
    border-radius: 4px !important;
    border: 1px solid #d1d5db;
    cursor: pointer !important;
    outline: none !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.filterType select {
    cursor: auto;
}

select:focus {
    border: 1px solid #6b7280;
}

.adminseacrh select {
    border: none;
    padding: 8px 10px;
}

.adminseacrh select:focus {
    border: none;
}

input[type="text"],
input[type="number"],
input[type="Number"],
input[type="email"],
input[type="password"],
textarea {
    padding: 8px 12px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    border-radius: 4px !important;
    font-weight: 400 !important;
    box-shadow: none !important;
}

.bg input {
    padding: 12px 12px !important;
    font-size: 15px !important;
}

.bg button {
    padding: 10px !important;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="Number"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus {
    border-color: #454545;
}

input[type="date"] {
    padding: 8px 12px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    border-radius: 4px !important;
    font-weight: 400 !important;
    cursor: pointer !important;
}

.select-arrow {
    position: absolute;
    top: 50%;
    right: 14px;
    z-index: 5 !important;
    transform: translateY(-50%);
    border-top: 5px solid #666b78;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

summary {
    cursor: pointer !important;
}

.data-form {
    border-radius: 10px;
    background-color: white;
    border: 1px solid #d1d5db;
    padding: 30px;
    margin: 0 auto
}

form label {
    display: block;
    color: #484848;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 4px;
    text-transform: capitalize !important;
}

.MuiRating-root label {
    font-size: 25px !important;
    color: inherit !important;
}

.rdt_TableHeader {
    border-bottom: 1px solid #d1d5db;
}

.autocomplete .MuiFormControl-root .MuiInputBase-root {
    padding: 9px 35px 9px 12px !important;

}

.autocomplete .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline,
.productLaunch .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border-color: #d1d5db !important;
    border-radius: 4px;
}

.autocomplete .MuiFormControl-root .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    /* border-color: black !important; */
    border: 1px solid #454545 !important;
    outline: none !important;
}

.autocomplete .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
    padding: 0 !important;
    line-height: 20px !important;
    font-size: 14px !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
}

.autocomplete .MuiFormControl-root .MuiAutocomplete-selected-value {
    text-overflow: ellipsis !important;

}

.autocomplete .MuiFormControl-root .MuiInputBase-root input::placeholder {
    color: black !important;
}

.productLaunch .MuiStack-root {
    padding: 0;
    overflow: hidden;
}

.contryCode .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border-radius: 4px 0 0 4px !important;
    border-right: none;
}

.contryCode input {
    border-radius: 0px 4px 4px 0 !important;
    /* border-left: none; */
}

.nb-spinner {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}